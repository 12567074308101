@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  font-weight: 600;
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Bold.ttf');
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

::placeholder {
  color: #7b7382;
}

body {
  img,
  svg {
    display: inline;
  }
}
.StepProgress {
  position: relative;
  list-style: none;
}
.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: -10px;
  left: 7px;
  width: 10px;
  height: 113%;
  border-left: 2px dashed #e7cefc;
  z-index: -1;
}
